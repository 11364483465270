<template>
  <a-modal
    title="回复"
    centered
    v-model="isShow"
    @ok="handleSubmit"
    :mask-closable="false"
    okText="确认"
    :ok-button-props="{ props: { loading: submitting } }"
  >
    <a-form :form="form">
      <a-form-item>
        <a-textarea
          :rows="4"
          :value="value"
          v-decorator="[
            'comment',
            { rules: [{ required: true, message: '请输入评论!' }] },
          ]"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { CommentModel } from "@/models/comment";
const commentModel = new CommentModel();

export default {
  name: "ReplyCommentModal",
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    recordableType: {
      type: String,
      required: true
    },
    recordableId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'reply_comment' }),
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },  
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          values.recordable_type = this.recordableType
          values.recordable_id = this.recordableId
          values.parent_id = this.id
          values.user_id = 0
          commentModel.create(values).then((res) => {
            if (res.code == 0) {
              this.$notification.open({
                message: "提示",
                description: res.message,
                duration: 2
              })
              this.isShow = false
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
